export default [
  {
    path: '/shadowx/plans',
    name: 'shadowx-plans',
    component: () => import('@/views/shadowx/Plans.vue'),
    meta: {
      pageTitle: 'ShadowX Plan',
      breadcrumb: [
        {
          text: 'ShadowX',
        },
        {
          text: 'Plans 套餐',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shadowx/plans/:id',
    name: 'shadowx-plan-info',
    component: () => import('@/views/shadowx/PlanInfo.vue'),
    meta: {
      pageTitle: 'ShadowX Plan',
      breadcrumb: [
        {
          text: 'ShadowX',
        },
        {
          text: 'Plan ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shadowx/orders',
    name: 'shadowx-orders',
    component: () => import('@/views/shadowx/Orders.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'ShadowX',
        },
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/shadowx/orders/:id',
    name: 'shadowx-order-info',
    component: () => import('@/views/shadowx/OrderInfo.vue'),
    meta: {
      pageTitle: 'Order Info',
      breadcrumb: [
        {
          text: 'ShadowX',
        },
        {
          text: 'Order Info',
          active: true,
        },
      ],
    },
  },
]
