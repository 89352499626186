export default [
  {
    path: '/mailx/plans',
    name: 'mailx-plans',
    component: () => import('@/views/mailx/Plans.vue'),
    meta: {
      pageTitle: 'MailX Plan',
      breadcrumb: [
        {
          text: 'MailX',
        },
        {
          text: 'Plans 套餐',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mailx/plans/:id',
    name: 'mailx-plan-info',
    component: () => import('@/views/mailx/PlanInfo.vue'),
    meta: {
      pageTitle: 'MailX',
      breadcrumb: [
        {
          text: 'Mailx',
        },
        {
          text: 'MailX Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mailx/orders',
    name: 'mailx-orders',
    component: () => import('@/views/mailx/Orders.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'MailX',
        },
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mailx/orders/:id',
    name: 'mailx-order-info',
    component: () => import('@/views/mailx/OrderInfo.vue'),
    meta: {
      pageTitle: 'Order Info',
      breadcrumb: [
        {
          text: 'Mailx',
        },
        {
          text: 'Order Info',
          active: true,
        },
      ],
    },
  },
]
