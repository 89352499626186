export default {
  // Endpoints
  loginEndpoint: '/auth/token',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/auth/logout',
  getMeEndpoint: '/user',
  passwordEndpoint: '/password',
  passwordVerifyEndpoint: '/password/verify',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
