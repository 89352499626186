export default [
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/pay/Pay.vue'),
    meta: {
      pageTitle: 'Pay',
      breadcrumb: [
        {
          text: 'pay',
        },
        {
          text: 'Pay',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pay/alipay',
    name: 'pay-alipay',
    component: () => import('@/views/pay/Alipay.vue'),
    meta: {
      pageTitle: 'Alipay',
      breadcrumb: [
        {
          text: 'Alipay',
        },
        {
          text: 'Alipay',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pay/code',
    name: 'pay-code',
    component: () => import('@/views/pay/Code.vue'),
    meta: {
      pageTitle: 'Code',
      breadcrumb: [
        {
          text: 'Code',
        },
        {
          text: 'Code',
          active: true,
        },
      ],
    },
  },
]
